import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, TopLine2, Heading, Subtitle, BtnWrap, Column2, ImgWrap,Img } from './InfoElements'


export const InfoSection = ({lightBg, id, imgStart, topLine, topLine2, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark,dark2}) => {
  return (
    <>
    <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
            <InfoRow imgStart={imgStart} >
                <Column1>
                    <TextWrapper>
                        <TopLine>{topLine}</TopLine>
                        <TopLine2>{topLine2}</TopLine2>
                        <Heading lightText={lightText}>{headline}</Heading>
                        <Subtitle darkText={darkText}>{description}</Subtitle>
                        {/* <BtnWrap>
                            <Button to='contact'
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                            >{buttonLabel}</Button>
                        </BtnWrap> */}
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                    <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>

    </>
  )
}
